import React from 'react';
import { MainLayout } from '../../components/Layout';
import { withPrefix } from 'gatsby';

import imgBg from '../../assets/img/home/bg-wave.png';
import imgSuccess from '../../assets/img/icons/success.svg';

import ContactMap from '../../components/Map/ContactMap';

export default () => {
  return (
    <MainLayout>
      <div
        className="parallax-"
        style={{
          minHeight: `770px`,
          backgroundImage: `url(${withPrefix(imgBg)})`,
        }}
      >
        <section className="container mt-20 mt-5-md section-contact">
          <div className="row">
            <div className="col-4 col-12-sm pl-10 pl-5-md pr-5-md">
              <div className="roboto bold font-size-xl text-align-center-md">
                Contactez-nous
              </div>
              <ContactMap />
            </div>

            <div className="col-6 col-12-sm">
              <div className="text-align-center mt-10-md">
                <img src={imgSuccess} alt="Alacaza" />
              </div>
              <div className="text-align-center pt-5 roboto bold font-size-xl">
                Merci
              </div>
              <div className="text-align-center pt-5 font-size-xl">
                Votre message a bien été transmis !
              </div>
            </div>
          </div>
        </section>
      </div>
    </MainLayout>
  );
};
